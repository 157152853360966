import { useEffect, useState } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import { toast } from 'react-hot-toast';
import Button from '../components/Button';
import Text from '../components/Text';
import { IS_TEST, counselor } from '../constants';

const Notice = ({ onReady, worry }) => {
  const [submitStarted, setSubmitStarted] = useState(false);
  const [registered, setRegistsered] = useState(false);

  const utmParams = new URLSearchParams(window.location.search);
  const URL = IS_TEST
    ? 'https://test.tarosister.com/api/v1/user/revive'
    : `https://api.tarosister.com/api/v1/user/revive`;

  const userRegisterHandler = async () => {
    if (!utmParams.has('key')) {
      toast.error('오류가 발생했습니다.');
      return;
    }

    console.log(worry);

    const key = utmParams.get('key');
    if (submitStarted) return;
    if (registered) return;

    setSubmitStarted(prev => true);

    try {
      const response = await fetch(URL, {
        method: 'POST',
        body: JSON.stringify({
          key,
          worry,
        }),
        headers: {
          'Content-type': 'application/json',
        },
      });

      if (response.status === 400 && !response.ok) {
        amplitude.getInstance().logEvent('already_registered', {
          key,
          referral: 'painful-crm',
          counselor,
        });
        setTimeout(() => {
          window.open(`https://tarot-sister.com/${window.location.search}`, '_self');
        }, 2000);
      } else if (response.status !== 400 && !response.ok) {
        toast.error('대상 고객이 아닙니다.');
        throw new Error(`${response.status} error`);
      } else {
        const identify = new amplitude.Identify().setOnce('key', key);
        amplitude.getInstance().identify(identify);
        onReady();
        setRegistsered(prev => true);
      }
    } catch (error) {
      console.log(error);
      // Sentry.captureException(error);
      // Sentry.captureMessage(error.message);
    }
    setSubmitStarted(prev => false);
  };

  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    amplitude.getInstance().logEvent('page_third', { referral: 'painful-crm', counselor });
    amplitude.getInstance().logEvent('typed_worry', { referral: 'painful-crm', counselor });
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <Text style={{ width: '100%', marginTop: '1.6rem' }}>지금 통화가 가능하신가요?</Text>
      <SubText>
        선생님들 시간 관계상{' '}
        <span
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '1.7rem',
            lineHeight: '180%',
            color: '#E1354C',
          }}>
          지금 통화 가능하신 분<br />
        </span>
        을 먼저 도와드리고 예약하시는 분은 상담 순위가 다소 밀릴 수 있습니다.
      </SubText>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          position: 'fixed',
          padding: '0 2rem',
          bottom: '1.6rem',
          left: 0,
          right: 0,
        }}>
        <Button
          width="100%"
          backgroundColor="#222529"
          text="통화 가능합니다"
          color="#fff"
          style={{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '2rem',
            textAlign: 'center',
          }}
          onClickHandler={userRegisterHandler}
        />
      </div>
    </div>
  );
};

const SubText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 180%;
  color: #2e1717;
  margin-top: 1.6rem;
`;
export default Notice;
